#eventos .logoBotaoHeader {
	width: 100vw;
	display: flex;
	justify-content: space-between;
	padding: 2vh 0 4vh 0;
	padding-left: 5vh;
	padding-right: 5vh;
	gap: 1vw;
}

#eventos .imgLogo {
	border-radius: 1vh;
	width: 15vh;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}

#eventos .botaoLogin {
	border-radius: 1vh;
	height: 5vh;
	width: 15vw;
	text-transform: capitalize;
}

#eventos .botaoLogin:hover {
	background-color: transparent;
}
#eventos .campoPesquisa {
	height: 5vh;
	display: flex;
	border-radius: 0.5rem;
	justify-content: center;
	background-color: #ffffff;
	border: 1px solid #eaeaea;
	/* box-shadow: rgba(25, 31, 40, 0.08) 0px 4px 6px; */
} 

#eventos .container {
	min-width: 30vw;
	max-width: 30vw;
	padding: 1vw 1vh;
	margin-bottom: 1vh;
	border-radius: 1px solid transparent;
	border-radius: 0.5vh;
}

#eventos .listaEventos {
	display: grid;
	gap: 2vh;
	grid-template-columns: 1fr 1fr 1fr;
}

#eventos .titulo {
	font-size: 1.2rem;
	margin: 1vh 0 1vh;
}

#eventos .imgEventos {
	width: 100%;
	height: 25vh;
	border-radius: 0.875rem;
	padding: 0%;
}

#eventos .iconPag {
	width: 2vw;
	height: 2vw;
	margin-bottom: 1vh;
}

#eventos .containerDetails {
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	height: 10vh;
	margin-top: 1.3vh;
}

#eventos .dataEventos {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-right: 1vw;
}

#eventos .descricaoEvento {
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	width: auto;
	text-align: left;
	float: left;
	white-space: pre-wrap;
	line-height: 1.1rem;
}

#eventos .buttonContatoPolitica {
	border-radius: 0.5vh;
	height: 5vh;
	/* background: none; */
	box-shadow: none;
}

#eventos .boxConatinerDetails {
	width: 94vw;
	display: flex;
	justify-content: space-between;
	flex-direction: row;
}

#eventos .boxDetails {
	width: 35vw;
}

#eventos .tituloEventoDetails {
	font-size: 1.4rem;
	margin-bottom: 1vh;
}
.tituloEvento::first-letter {
	text-transform: uppercase;
}

#eventos .dataEventoDetails {
	font-size: 1rem;
	display: flex;
	align-items: flex-start;
	margin-bottom: 1vh;
	color: #808080;
}

#eventos .tituloDescricaoDetails {
	font-size: 1.2rem;
	margin-bottom: 1vh;
}

#eventos .textoDetails {
	font-size: 1rem;
	margin-bottom: 1vh;
	color: #808080;
	white-space: pre-wrap;
}

#eventos .pageIngressos {
	width: 50vw;
}

#eventos .botaoIncricao {
	text-transform: capitalize;
	/* background: #85401d; */
	color: white;
	width: 30vw;
	border-radius: 0.625rem;
}

#eventos .containerEventsPay {
	display: flex;
	flex-direction: row;
	gap: 0;
	width: 100vw;
}

#eventos .boxTicket {
	width: 30vw;
	height: auto;
	background: #fbfbfb;
	margin-bottom: 2vh;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
}

#eventos .cupomEventsPay {
	width: 30vw;
	margin-left: 3vw;
	margin-top: 5vh;
}

#eventos .boxInfoParticipante {
	width: 55vw;
	height: auto;
	background: #fbfbfb;
	margin-bottom: 2vh;
	display: flex;
	flex-direction: column;
	justify-content: start;
}

#eventos .infoParticipantePag {
	/* width: 55vw; */
	margin-left: 3vw;
	margin-right: 3vw;
	margin-top: 1vh;
}

#eventos .boxPay {
	width: 55vw;
	height: auto;
	background: #fbfbfb;
	margin-bottom: 2vh;
	display: flex;
	flex-direction: column;
	justify-content: start;
}

#eventos .buttonCupom {
	background: #70d93f;
	color: white;
	height: 4vh;
	padding: 1vw 2vw;
}

#eventos .boxIngresso {
	width: 100%;
	height: auto;
	background: #fbfbfb;
	margin-bottom: 2vh;
	display: flex;
	flex-direction: column;
	justify-content: start;
	border-radius: 0.625rem;
}

#eventos .ButtonMenos {
	padding: 0vw;
	min-width: 3vw;
	background: #d9d9d9;
	text-align: center;
	color: black;
	font-weight: 900;
}

#eventos .ButtonMais {
	padding: 0vw;
	min-width: 3vw;
	text-align: center;
	color: #d9d9d9;
	font-weight: 900;
}

#eventos .boxLogin {
	width: 40vw;
	justify-content: center;
	align-items: center;
}

#eventos .modalWarning {
	font-size: 1.25rem;
	color: #2e2e2e;
}

#eventos .bannerIngresso {
	height: 15vh;
}

/* style={{
								marginTop: '2vh',
								fontFamily: p600,
								fontSize: '1.5rem',
								width: '15vh',
								height: '4vh',
								borderRadius: '.5rem',
								color: 'white',
								backgroundColor: '#85401D',
							}} */

/* #eventos .buttonfecharModalWarning{
	margin-top: 2vh;
	font-size: 1.5rem;
	width: 15vh;
	height: 4vh;
	border-radius: .625rem;
	background-color: #85401D;


} */

@media screen and (max-width: 600px) {
	#eventos .modalWarning {
		font-size: 0.875rem;
	}

	#eventos .boxLogin {
		width: 80vw;
	}

	#eventos .containerEventsPay {
		flex-direction: column-reverse;
	}

	#eventos .cupomEventsPay {
		width: 94vw;
		margin-top: 2vh;
	}

	#eventos .boxTicket {
		width: auto;
	}

	#eventos .boxPay {
		width: 94vw;
	}

	#eventos .tituloEventoDetails {
		font-size: 1.25rem;
	}
	#eventos .dataEventoDetails {
		font-size: 0.875rem;
	}

	#eventos .textoDetails {
		font-size: 0.875rem;
	}

	#eventos .botaoIncricao {
		width: 90vw;
	}

	#eventos .tituloDescricaoDetails {
		font-size: 1rem;
	}

	#eventos .buttonCupom {
		height: auto;
	}
	.buttonContatoPolitica {
		background: none;
		border: none;
		cursor: pointer;
		text-decoration: underline;
		font-size: 10px;
		height: auto;
		padding: 0;
		margin: 0;
		box-shadow: none;
		color: white; /* Para manter a cor do texto padrão */
	}

	#eventos .pageIngressos {
		width: auto;
	}

	#eventos .boxIngresso {
		border-radius: 0.625rem;
		margin: 2vh 0;
	}

	#eventos .ButtonMenos {
		min-width: 10vw;
	}

	#eventos .ButtonMais {
		min-width: 10vw;
	}

	#eventos .listaEventos {
		display: grid;
		grid-template-columns: 1fr 1fr;

		.container {
			max-width: none;
			padding: 0rem;
			/* margin-bottom: 2.4vh; */
		}

		.imgEventos {
			height: 12vh;
		}

		.data {
			font-size: 12px;
			padding: 0 5px 0 0;
			font-weight: 800 !important;
		}

		.containerDetails {
			display: block;
		}

		.dataEventos {
			flex-direction: row;
			justify-content: left;

			font-size: 12px;
		}
		.tituloEvento {
			font-size: 0.875rem;
		}

		.descricaoEvento {
			font-size: 0.75rem;
		}

		.descricaoEvento::first-letter {
			width: auto;
			text-transform: uppercase;
			width: 0;
		}
	}

	#eventos .headerDesktop {
		display: none;
	}

	/* #eventos .imgLogo {
		width: 10vh;
	} */

	#eventos .headerMobile {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	#eventos .headerMobile {
		width: 94%;
		padding-bottom: 2vh;
	}

	#eventos .logoBotaoHeader {
		display: flex;
		justify-content: space-between;
		padding: 1vh 0 0 0;
		padding-left: 3.2vw;
		padding-right: 3.2vw;
	}

	#eventos .botaoLogin {
		width: auto;
		font-size: 0.75rem;
	}


	#eventos .mobileCampoPesquisa {
		width: 100%;
		margin-top: 1.5vh;
	}

	#eventos .iconPag {
		width: 5.5vw;
		height: 5.5vw;
		margin-bottom: 1vh;
	}
}
