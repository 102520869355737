#pos .boxHome {
	height: 100vh;
	width: 100vw;
	padding: 20vh 0;
	display: flex;
	flex-direction: column;
	align-items: center;
	background: #f3f3f3;
}

#pos .boxLogos {
	display: flex;
	align-items: center;
}

#pos .typoModalKeyboard {
	font-size: 1.4rem;
	color: #3e3e3e;
}

#pos .btnValidarTicket {
	/* width: 32vw; */
	color: white;
	font-size: 0.875rem;
	box-shadow: none;
	text-transform: uppercase;
	/* height: 8vh; */
	margin-top: 0vh !important;
	border-radius: 0.5rem;
}

#pos .hg-theme-default {
	margin: 2vh 0;
	padding: 0px !important;
}

#pos #pos .boxTextBtn {
	display: flex;
	align-items: center;
	gap: 1.5vw;
}

#pos .textFieldCupom {
	width: 80vw;
}

#pos .textFieldCupomValidar {
	min-width: 82vw;
}

#pos .MuiOutlinedInput-notchedOutline {
	border-color: #a2a2a2 !important;
}

#pos .logoCliente {
	width: 18vw;
	height: auto;
	/* margin: 0 3vw; */
	display: block;
}

#pos .listaDepartamento {
	font-size: 0.875rem;
	text-transform: lowercase;
}

#pos .listaDepartamento::first-letter {
	text-transform: uppercase;
}

#pos .cardBoxProdutos,
.cardBoxPag {
	margin: 2vw;
	box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.032), 0px 2px 4px 0px rgba(0, 0, 0, 0.01), 0px 1px 8px 0px rgba(0, 0, 0, 0.01);
	background: white;
	border: 1px solid #f5f5f5;
	display: flex;
	align-items: center;
	border-radius: 0.75rem;
}

#pos .cardBoxInternaProd {
	display: flex;
	flex-direction: row;
	height: 15vh;
	width: 96vw;
	align-items: flex-start;
	padding: 0.5vh;
	justify-content: space-between;
	transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

#pos .imgProduto {
	width: 32vw;
	height: 14vh;
	border-radius: 0.625rem;
	max-width: none;
}

#pos .imgProdutoCar {
	width: 20vw;
	height: 14vh;
	border-radius: 0.625rem;
	max-width: none;
}

#pos .nomeProduto {
	/* display: flex; */
	margin-top: 1vh;
	font-size: 0.7923rem;
	color: #3e3e3e;
	text-transform: lowercase;
	text-align: start;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
	-webkit-line-clamp: 2; /* Mostra no máximo 2 linhas */
}

#pos .btnAdd {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 15vw;
	background: #64c832;
	border-radius: 25px;
	margin-right: 1vw;
	height: 4.5vh;
}

#pos .boxBtnAdd {
	margin-top: 7vh;
}

#pos .typoAdd {
	font-size: 0.75rem;
	color: white;
}

#pos .nomeProdutoCart {
	color: #3e3e3e;
	text-transform: lowercase;
	text-align: start;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
	-webkit-line-clamp: 2; /* Mostra no máximo 2 linhas */
}

#pos .nomeProdutoTxtCart {
	font-size: 0.7rem;
	color: #56585c;
	text-align: start;
}

#pos .precoProduto {
	font-size: 0.875rem;
	display: flex;
	color: #56585c;
}

#pos .boxMaisMenosProd {
	display: flex;
	align-items: center;
	justify-content: end;
	width: 35vw;
	margin-top: 3vh;
	margin-right: 1vw;
}

#pos .boxMaisMenosProdCar {
	display: flex;
	align-items: center;
	width: 32vw;
	margin: 1.5vh 0;
}

#pos .boxBotaoMaisProd {
	border-radius: 0.9375rem;
	display: flex;
	justify-content: center;
	width: 12vw;
	height: 7vh;
}

#pos .botaoMais {
	color: white;
	font-size: 1.25rem;
}

#pos .boxBotaoMenosProd {
	background: #fafafa;
	border-radius: 0.9375rem;
	display: flex;
	justify-content: center;
	width: 12vw;
	height: 7vh;
}

#pos .botaoMenos {
	color: #a6a5a5;
	font-size: 1.25rem;
}

#pos .quantidadeProduto {
	font-size: 1.125rem;
	padding: 0 1.5vh;
}

#pos .formaPag {
	margin-bottom: 1.5vh;
}

#pos .formaPagSelecione {
	color: #3e3e3e;
	font-size: 1rem;
	/* padding: 2.5vh; */
	display: flex;
}

#pos .cardBoxPag {
	display: flex;
	align-items: center;
	padding: 0.15vh 0 0.15vh 9vw;
	height: 11vh;
}

#pos .boxBtnVoltar {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 5vh;
}

#pos .iconePag {
	font-size: 2rem;
	color: #3e3e3e;
}

#pos .iconePagSacola {
	font-size: 1.5rem;
	color: #3e3e3e;
}

#pos .iconSeta {
	font-size: 1.2rem;
}

#pos .boxProdutosCarrinho {
	margin: 10vh 0 2vh 0;
	overflow: scroll;
	height: 59vh;
}

#pos .boxLimparCarrinho {
	display: flex;
	justify-content: flex-end;
	margin-right: 0.5rem;
	font-size: 0.75rem;
}

#pos .btnContinuarComprando {
	box-shadow: none;
	text-transform: lowercase;
	height: 7vh;
	width: 95vw;
	margin-top: 1.5vh;
	border-radius: 0.9375rem;
}

#pos .txtContinuarComprando {
	font-size: 0.9rem;
	&::first-letter {
		text-transform: uppercase;
	}
}

#pos .boxPrecoFinalCarrinho {
	display: flex;
	justify-content: space-between;
	padding: 0 3vw;
}

#pos .typoPrecoFinal {
	font-size: 1.25rem;
	color: #3e3e3e;
	margin-top: 1.5vh;
}

#pos .boxVoltar {
	display: flex;
	justify-content: center;
	margin-bottom: 1vh;
}

#pos .typoVoltar {
	text-decoration: underline;
	font-size: 0.875rem;
}

#pos .btnCarrinho {
	box-shadow: none;
	text-transform: lowercase;
	height: 7vh;
	margin: 1.5vh 0;
	border-radius: 0.9375rem;
}

#pos .smallbtnCarrinho {
	box-shadow: none;
	text-transform: lowercase;
	height: 5vh;
	margin: 1.5vh 0;
	border-radius: 0.9375rem;
}

#pos .posCartName {
	font-size: 0.875rem;
	color: white;

	&::first-letter {
		text-transform: capitalize;
	}
}

#pos .typoItens {
	font-size: 1rem;
	display: flex;
	color: #3e3e3e;
	align-items: center;
}

#pos .typoPrecoCarrinho {
	font-size: 1rem;
	display: flex;
	color: #3e3e3e;
	margin-right: 4px;
}

#pos .drawerOptional {
	display: flex;
	align-items: center;
	height: 100vh;
	padding-bottom: 24vh;
	/* border-radius: 1rem 1rem 0 0; */
}

#pos .boxCarrinho {
	position: fixed;
	bottom: 0vh;
	left: 0;
	right: 0;
	width: 100vw;
	/* height: 16vh; */
	z-index: 2;
	background-color: white;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	/* padding: 1vh 3vw 1.5vh 3vw; */
	box-shadow: 0px 15px 10px rgba(0, 0, 0, 0.032), 0px -2px 10px rgba(0, 0, 0, 0.1), 0px 3px 12px rgba(0, 0, 0, 0.101);
	border-top: 1px solid #dedede;
}

#pos .typoTotalItens {
	display: flex;
	width: 90vw;
	justify-content: space-between;
	align-items: center;
}

#pos .boxOpicional {
	display: flex;
	flex-direction: column;
	align-items: center;
}

#pos .boxTitulo {
	background: #f5f5f5;
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100vw;
	padding: 2.2vh 3vw;
}

#pos .escolhaOpcao {
	font-size: 1rem;
	color: #3e3e3e;
}

#pos .escolhaMinMax {
	color: #56585c;
	font-size: 0.75rem;
}

#pos .btnMenosMaisOpcional {
	background: #a2a2a2;
	border-radius: 50%;
	color: white;
	height: 5vh;
	min-width: 0px;
	width: 10vw;
	font-size: 1.25rem;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0px;
}

#pos .opcionalObrigatorio {
	border-radius: 0.9375rem;
}

#pos .typoObrigatorio {
	font-size: 0.75rem;
	padding: 0.5vh 3vw;
	color: white;
}

#pos .opcionais {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 1vh 0;
}

#pos .boxOpcionalOpcao {
	width: 95vw;
}

#pos .opcaoFontSize {
	font-size: 0.875rem;
	color: #3e3e3e;
}

#pos .precoOpcional {
	font-size: 0.75rem;
	color: #56585c;
}

#pos .logoSicred {
	height: 10vh;
}

#pos .logoZerofila {
	height: 6vh;
}

#pos .logoSicred {
	height: 4vh;
	margin-top: 5vh;
}

#pos .boxBtnSettings {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

#pos .typoOpcoesAdmSettings {
	color: #3e3e3e;
	display: flex;
	justify-content: center;
	text-align: center !important;
	margin-bottom: 1vh;
	width: 100%;
	padding: 0 5vw;
	margin-top: 2vh;
}

#pos .boxModalCenter {
	width: 81vw;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 100vh;
	width: 100vw;
}

#pos .boxModalSettings {
	overflow-y: auto;
	width: 90%;
	max-height: 85vh;
	background-color: #f5f5f5;
	border: none;
	padding: 0 5vw 5vw 5vw;
	border-radius: 0.9375rem;
}

#pos .btnSttings {
	font-size: 0.775rem;
	width: 80vw;
	text-transform: uppercase;
	box-shadow: none;
	min-height: 8vh;
	margin-bottom: 1.2vh;
	border-radius: 0.9375rem;
	background: #fff;
	color: #3e3e3e;
	box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.032), 0px 2px 4px 0px rgba(0, 0, 0, 0.01), 0px 1px 8px 0px rgba(0, 0, 0, 0.01);
}

#pos .btnCloseSetings {
	color: #a2a2a2;
	font-size: 1.5rem;
	margin-top: 1.5vh;
}

#pos .btnCloseQrCode {
	color: #a2a2a2;
	font-size: 1.5rem;
}

#pos .containerBox {
	margin-top: 1.5vh;
	height: max-content;
	max-height: 75vh;
	/* overflow-y: auto; */
}

#pos .boxClose {
	display: flex;
	justify-content: flex-end;
}

#pos .boxContainerHistory {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 1.5vh;
}

#pos .containerCard {
	display: flex;
	box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.032), 0px 2px 4px 0px rgba(0, 0, 0, 0.01), 0px 1px 8px 0px rgba(0, 0, 0, 0.01);
	background: white;
	width: 94vw;
	border-radius: 0.75rem;
	flex-direction: column;
	align-items: center;
	padding: 1vh 1vh;
}

#pos .boxCard {
	width: 90vw;
	margin: 2vh 0;
}

#pos .boxRestauranteCodigo {
	display: flex;
	justify-content: space-between;
}

#pos .btnReimprir {
	width: 90vw;
	border-radius: 0;
	color: #3ea33f;
	font-size: 0.875rem;
	border-top: 1px solid #bbbbbb;
	text-transform: capitalize;
}

#pos .typoTextModalWarning {
	font-size: 0.875rem;
	margin-bottom: 2.5vh;
	display: flex;
	justify-content: center;
	text-align: center !important;
	width: 100%;
	padding: 0 5vw;
}

#pos .typoTextModalWarningMultiline {
	font-size: 0.875rem;
	display: flex;
	justify-content: center;
	text-align: center !important;
	width: 100%;
	padding: 0 5vw;
}

#pos .typoTextModalInfo {
	font-size: 0.7rem;
	display: flex;
	width: 100%;
	padding: 0 5vw;
	text-align: left !important;
}

#pos .typoTextModalInfoCenter {
	font-size: 0.7rem;
	text-align: center !important;
	display: flex;
	justify-content: center;
	width: 100%;
	padding: 0 5vw;
}

#pos .typoTitleModalWarning {
	font-size: 1rem;
	margin-bottom: 2.5vh;
	text-align: center;
}

#pos .css-1t1j96h-MuiPaper-root-MuiDialog-paper {
	border-radius: 0.9375rem;
	display: flex;
	align-items: center;
	margin: 0;
	max-height: none;
	max-width: none;
}

#pos .boxDialog {
	margin: 2vh;
	display: flex;
	flex-direction: column;
	align-items: center;
}

#pos .btnOkWarnings {
	font-size: 0.875rem;
	color: white;
	background: linear-gradient(to right, rgb(100, 200, 50), rgb(52, 174, 41));
	border-radius: 0.9375rem;
	width: 100%;
	height: 7vh;
	margin-bottom: 0vh;
}

#pos .btnYesNo {
	width: 45vw;
	display: flex;
	justify-content: space-evenly;
	width: 100%;
	margin-bottom: 3vh;
}

#pos .btnWarnings {
	font-size: 0.875rem;
	color: white;
	background: linear-gradient(to right, rgb(100, 200, 50), rgb(52, 174, 41));
	border-radius: 0.8rem;
	text-transform: capitalize;
	padding: 1vh 12vw;
}

#pos .boxPaymentMethods {
	height: 100vh;
	width: 100vw;
	background: #f3f3f3;
	padding-top: 20vh;
	display: flex;
	justify-content: center;
}

#pos .boxPay {
	display: flex;
	flex-direction: column;
	align-items: center;
}

#pos .typoTextPix {
	display: flex;
	text-align: center;
	margin-bottom: 2.5vh;
	font-size: 1rem;
	padding: 0 10vw;
}

#pos .qrCodePixPay {
	width: 60vw;
}

#pos .typoTitleCard {
	display: flex;
	text-align: center;
	margin-bottom: 8vh;
	padding: 0 10vw;
	font-size: 1.2rem;
}

.fontKeyboard {
	font-size: 1rem !important;
}

#pos .BoxInfos {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	margin: 2vh 0;
}

#pos .typoinfos {
	font-size: 0.875rem;
	color: #3e3e3e;
	margin-bottom: 0.5rem;
}

#pos .typoNameProduct {
	font-size: 1rem;
	margin-bottom: 1.5vh;
}

#pos .imgProduct {
	height: 15vh;
	border-radius: 0.9375rem;
	justify-content: center;
}

#pos .boxRestaurant {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	gap: 12px;
	margin: 0 3vw;
	justify-items: center;
}

#pos .restaurantImg img {
	height: 15vh;
	border-radius: 1.125rem;
	border: 1px rgb(235, 235, 235) solid;
}

.restaurantImg {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.nameRestaurant {
	color: #56585c;
	font-size: 0.875rem;
	text-align: center;
	margin-top: 8px;
}

#pos .cardUserInfoContainer {
	box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.032), 0px 2px 4px 0px rgba(0, 0, 0, 0.01), 0px 1px 8px 0px rgba(0, 0, 0, 0.01);
	border-radius: 0.75rem;
	margin-bottom: 2vh;
}

#pos .userInfoSection {
	display: flex;
	justify-content: space-between;
	padding: 2vh;
	align-items: center;
}
#pos .cashInfoSection {
	display: flex;
	padding: 1.5vh;
	justify-content: space-between;
}

#pos .userInfoContent {
	display: flex;
	align-items: start;
	flex-direction: column;
}

#pos .cardUserOptions {
	box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.032), 0px 2px 4px 0px rgba(0, 0, 0, 0.01), 0px 1px 8px 0px rgba(0, 0, 0, 0.01);
	border-radius: 0.75rem;
}

#pos .optionUser {
	display: flex;
	flex-direction: column;
	align-items: center;
	height: 7rem;
	justify-content: center;
}
#pos .boxActiveOption {
	display: flex;
	flex-direction: column;
	align-items: center;
	/* height: 7rem; */
	justify-content: center;
}

.boxSaleReport {
	display: flex;
	align-items: center;
	padding: 0.5vh;
}

.reportInfoSection {
	display: flex;
	justify-content: space-between;
	margin-bottom: 1vh;
}

.reportInfoGrid {
	display: grid;
	grid-template-columns: 3fr 1fr 2fr;
	margin-bottom: 1vh;
}

#pos .boxModalReprint {
	overflow-y: auto;
	width: 90%;
	max-height: 65vh;
	background-color: #f5f5f5;
	border: none;
	padding: 5vw;
	box-shadow: 24px;
	border-radius: 0.9375rem;
}

#pos .cardReprintModal {
	box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.032), 0px 2px 4px 0px rgba(0, 0, 0, 0.01), 0px 1px 8px 0px rgba(0, 0, 0, 0.01);
	border-radius: 0.75rem;
	margin-top: 2vh;
}

#pos .boxIconPrinter {
	background: #f5f5f5;
	border-radius: 100%;
	width: 2.9rem;
	height: 2.9rem;
	display: flex;
	align-items: center;
	justify-content: center;
}
